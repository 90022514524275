import 'ol/ol.css';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

var view = new View({
    center: [48.86109, 2.35201],
    zoom: 5
});

var map = new Map({
    target: 'mapHolder',
    layers: [
        new TileLayer({
            source: new OSM()
        })
    ],
    controls: [],
    view: view,
});

view.adjustCenter([48.86151,2.34954]);

/*

<iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=2.348467111587525%2C48.85947403460392%2C2.3555481433868413%2C48.86269970537148&amp;layer=mapnik&amp;marker=48.861086895973635%2C2.3520076274871826" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=48.86109&amp;mlon=2.35201#map=18/48.86109/2.35201">View Larger Map</a></small>

*/ 