import 'bootstrap/dist/css/bootstrap.css'
import './../css/*.css'

import 'bootstrap'

import $ from 'jquery'
import './owl.carousel'
import pageTransition from './dep'

// Global variables
var
userAgent = navigator.userAgent.toLowerCase(),
initialDate = new Date(),

$document = $(document),
$window = $(window),
$html = $("html"),
$body = $("body"),

isDesktop = $html.hasClass("desktop"),
isIE = userAgent.indexOf("msie") !== -1 ? parseInt(userAgent.split("msie")[1], 10) : userAgent.indexOf("trident") !== -1 ? 11 : userAgent.indexOf("edge") !== -1 ? 12 : false,
isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
windowReady = false,
isNoviBuilder = false,

plugins = {
	copyrightYear:           $( '.copyright-year' ),
	preloader:               $( '.preloader' ),
	wow:                     $( '.wow' ),
	materialParallax:        $( '.parallax-container' ),
	owl:                     $( '.owl-carousel' )
};

/**
* @desc Check the element was been scrolled into the view
* @param {object} elem - jQuery object
* @return {boolean}
*/
function isScrolledIntoView ( elem ) {
if ( isNoviBuilder ) return true;
return elem.offset().top + elem.outerHeight() >= $window.scrollTop() && elem.offset().top <= $window.scrollTop() + $window.height();
}
// Initialize scripts that require a loaded window
$window.on('load', function () {
	// Page loader & Page transition
	if ( plugins.preloader.length && !isNoviBuilder ) {
		pageTransition({
			target: document.querySelector( '.page' ),
			delay: 0,
			duration: 500,
			classIn: 'fadeIn',
			classOut: 'fadeOut',
			classActive: 'animated',
			conditions: function (event, link) {
				return link && !/(\#|javascript:void\(0\)|callto:|tel:|mailto:|:\/\/)/.test(link) && !event.currentTarget.hasAttribute('data-lightgallery');
			},
			onTransitionStart: function ( options ) {
				setTimeout( function () {
					plugins.preloader.removeClass('loaded');
				}, options.duration * .75 );
			},
			onReady: function () {
				plugins.preloader.addClass('loaded');
				windowReady = true;
			}
		});
	}

	// Material Parallax
	if ( plugins.materialParallax.length ) {
		if ( !isNoviBuilder && !isIE && !isMobile) {
			plugins.materialParallax.parallax();
		} else {
			for ( var i = 0; i < plugins.materialParallax.length; i++ ) {
				var $parallax = $(plugins.materialParallax[i]);

				$parallax.addClass( 'parallax-disabled' );
				$parallax.css({ "background-image": 'url('+ $parallax.data("parallax-img") +')' });
			}
		}
	}
});

// Initialize scripts that require a finished document
$(function () {
	isNoviBuilder = window.xMode;

	/**
	 * @desc Initialize owl carousel plugin
	 * @param {object} carousel - carousel jQuery object
	 */
	function initOwlCarousel ( carousel ) {
		var
			aliaces = [ '-', '-sm-', '-md-', '-lg-', '-xl-', '-xxl-' ],
			values = [ 0, 480, 768, 992, 1200, 1600 ],
			responsive = {};

		for ( var j = 0; j < values.length; j++ ) {
			responsive[ values[ j ] ] = {};
			for ( var k = j; k >= -1; k-- ) {
				if ( !responsive[ values[ j ] ][ 'items' ] && carousel.attr( 'data' + aliaces[ k ] + 'items' ) ) {
					responsive[ values[ j ] ][ 'items' ] = k < 0 ? 1 : parseInt( carousel.attr( 'data' + aliaces[ k ] + 'items' ), 10 );
				}
				if ( !responsive[ values[ j ] ][ 'stagePadding' ] && responsive[ values[ j ] ][ 'stagePadding' ] !== 0 && carousel.attr( 'data' + aliaces[ k ] + 'stage-padding' ) ) {
					responsive[ values[ j ] ][ 'stagePadding' ] = k < 0 ? 0 : parseInt( carousel.attr( 'data' + aliaces[ k ] + 'stage-padding' ), 10 );
				}
				if ( !responsive[ values[ j ] ][ 'margin' ] && responsive[ values[ j ] ][ 'margin' ] !== 0 && carousel.attr( 'data' + aliaces[ k ] + 'margin' ) ) {
					responsive[ values[ j ] ][ 'margin' ] = k < 0 ? 30 : parseInt( carousel.attr( 'data' + aliaces[ k ] + 'margin' ), 10 );
				}
			}
		}

		// Enable custom pagination
		if ( carousel.attr( 'data-dots-custom' ) ) {
			carousel.on( 'initialized.owl.carousel', function ( event ) {
				var
					carousel = $( event.currentTarget ),
					customPag = $( carousel.attr( 'data-dots-custom' ) ),
					active = 0;

				if ( carousel.attr( 'data-active' ) ) {
					active = parseInt( carousel.attr( 'data-active' ), 10 );
				}

				carousel.trigger( 'to.owl.carousel', [ active, 300, true ] );
				customPag.find( '[data-owl-item="' + active + '"]' ).addClass( 'active' );

				customPag.find( '[data-owl-item]' ).on( 'click', function ( event ) {
					event.preventDefault();
					carousel.trigger( 'to.owl.carousel', [ parseInt( this.getAttribute( 'data-owl-item' ), 10 ), 300, true ] );
				} );

				carousel.on( 'translate.owl.carousel', function ( event ) {
					customPag.find( '.active' ).removeClass( 'active' );
					customPag.find( '[data-owl-item="' + event.item.index + '"]' ).addClass( 'active' )
				} );
			} );
		}

		carousel.owlCarousel( {
			autoplay:           isNoviBuilder ? false : carousel.attr( 'data-autoplay' ) !== 'false',
			autoplayTimeout:    carousel.attr( "data-autoplay" ) ? Number( carousel.attr( "data-autoplay" ) ) : 3000,
			autoplayHoverPause: true,
			loop:               isNoviBuilder ? false : carousel.attr( 'data-loop' ) !== 'false',
			items:              1,
			center:             carousel.attr( 'data-center' ) === 'true',
			dotsContainer:      carousel.attr( 'data-pagination-class' ) || false,
			navContainer:       carousel.attr( 'data-navigation-class' ) || false,
			mouseDrag:          isNoviBuilder ? false : carousel.attr( 'data-mouse-drag' ) !== 'false',
			nav:                carousel.attr( 'data-nav' ) === 'true',
			dots:               carousel.attr( 'data-dots' ) === 'true',
			dotsEach:           carousel.attr( 'data-dots-each' ) ? parseInt( carousel.attr( 'data-dots-each' ), 10 ) : false,
			animateIn:          carousel.attr( 'data-animation-in' ) ? carousel.attr( 'data-animation-in' ) : false,
			animateOut:         carousel.attr( 'data-animation-out' ) ? carousel.attr( 'data-animation-out' ) : false,
			responsive:         responsive,
			navText:            carousel.attr( 'data-nav-text' ) ? $.parseJSON( carousel.attr( 'data-nav-text' ) ) : [],
			navClass:           carousel.attr( 'data-nav-class' ) ? $.parseJSON( carousel.attr( 'data-nav-class' ) ) : [ 'owl-prev', 'owl-next' ]
		} );
	}

	// Additional class on html if mac os.
	if (navigator.platform.match(/(Mac)/i)) {
		$html.addClass("mac-os");
	}

	// Adds some loosing functionality to IE browsers (IE Polyfills)
	if (isIE) {
		if (isIE === 12) $html.addClass("ie-edge");
		if (isIE === 11) $html.addClass("ie-11");
		if (isIE < 10) $html.addClass("lt-ie-10");
		if (isIE < 11) $html.addClass("ie-10");
	}

	// Copyright Year (Evaluates correct copyright year)
	if (plugins.copyrightYear.length) {
		plugins.copyrightYear.text(initialDate.getFullYear());
	}

	// UI To Top
	if (isDesktop && !isNoviBuilder) {
		$().UItoTop({
			min: 800,
			easingType: 'easeOutQuad',
			containerClass: 'toTop fa fa-chevron-up'
		});
	}
	/*
	// Google maps
	if( plugins.maps.length ) {
		lazyInit( plugins.maps, initMaps );
	}
	*/
	// Owl carousel
	if ( plugins.owl.length ) {
		for ( var i = 0; i < plugins.owl.length; i++ ) {
			var carousel = $( plugins.owl[ i ] );
			plugins.owl[ i ].owl = carousel;
			initOwlCarousel( carousel );
		}
	}

	// WOW
	if ($html.hasClass("wow-animation") && plugins.wow.length && !isNoviBuilder && isDesktop) {
		new WOW().init();
	}
});